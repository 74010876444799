@font-face {
  font-family: "GothamMedium";
  font-style: normal;
  src: local("GothamMedium"), url("./fonts/GothamMedium.ttf") format("truetype"), url("./fonts/GothamMedium.otf") format("opentype");
}
@font-face {
  font-family: "GothamBook";
  font-style: normal;
  src: local("GothamBook"), url("./fonts/Gotham-Book.woff") format("woff"), url("./fonts/Gotham-Book.woff2") format("woff2"), url("./fonts/GothamBook.ttf") format("truetype"), url("./fonts/GothamBook.otf") format("opentype");
}
@font-face {
  font-family: "gasoek-one";
  font-style: normal;
  src: local("GasoekOne-Regular"), url("./fonts/GasoekOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "satoshi-regular";
  font-style: normal;
  src: local("Satoshi-Regular"), url("./fonts/Satoshi-Regular.otf") format("opentype");
}

body {
  margin: 0;
  font-family: 'GothamBook', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040608;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

a:not(.clean)::after {
  content: '';
  position: absolute;
  margin: 0 auto;
  height: 1px;
  width: 0;
  bottom: -8px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  transition: width 0.2s ease-out;
}

a:not(.clean):hover::after,
a:not(.clean):focus::after {
  width: 100%;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 28px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  letter-spacing: 5px;
  color: #ffffff;
  line-height: 1.1;
  font-family: 'GothamBook';
}

p, .p {
  line-height: 1.5;
  font-size: 18px;
  color: #999999;
  margin: 10px 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

ul > li,
ol > li {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  h1, .h1 {
    font-size: 34px;
  }
  h2, .h2 {
    font-size: 26px;
  }
  p, .p {
    font-size: 16px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
